import React from 'react';
import PageTitle from "../../../Layout/PageTitle";
import PageRow from "../../../Layout/PageRow";
import Error from "../../../App/components/Messages/Error";
import CreditNoteComponent from "./CreditNote";
import Search from "../../../Publisher/components/Search";
import Ajax from "../../../../Helper/Ajax";
import HelpFunctions from "../../../../Helper/HelpFunctions";

type CreditNoteCollectionProps = {
    match: {
        isExact: true,
        creditNoteCollectionId: string
    }
}

export type CreditNoteLineItemType = {
    id: string,
    creditNoteId: string,
    description: string,
    clicks: number,
    cpc: string,
    amount: string,
    position: number,
    createdDate: string,
    type: string,
}

export type CreditNoteType = {
    id: string,
    creditNoteCollectionId: string,
    publisherId: string,
    publisherName: string,
    subTotal: string,
    total: string,
    taxRate: string,
    taxAmount: string,
    createdDate: string,
    dateFrom: string,
    dateTo: string,
    releaseDate: string,
    notes: string,
    workflowStatus: string,
    creditNoteNumber: string,
    creditNoteLineItems: CreditNoteLineItemType[],
    creditNoteAggregateStatus: number,
}

type CreditNoteCollectionState = {
    creditNotes: CreditNoteType[],
    searchTerm: string,
    filter: string,
    isErrorCritical: boolean,
    errorCode: number,
    errorMessage: string
}

class CreditNoteCollection extends React.Component<CreditNoteCollectionProps, CreditNoteCollectionState>{
    state: CreditNoteCollectionState = {
        creditNotes: [],
        searchTerm: '',
        filter: 'all',
        isErrorCritical: true,
        errorCode: 0,
        errorMessage: '',
    };

    loadCreditNotesFromApi = () => {
        const collectionId = this.props.match.params.creditNoteCollectionId;

        const promise: Promise<string> = Ajax.createGetXhr(
            process.env.REACT_APP_API_URL + '/admin/credit-notes-for-month'
            + '?collectionId=' + collectionId
            + '&searchTerm=' + this.state.searchTerm
            + '&filter=' + this.state.filter
        );

        promise.then(response => {
            const parsedResponse = JSON.parse(response);

            if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
                this.setState({
                    isErrorCritical: !parsedResponse.success,
                    errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
                });
                return;
            }

            let creditNotes = [];

            if (parsedResponse.data.creditNotes) {
                creditNotes = parsedResponse.data.creditNotes;
            }

            this.setState({
                creditNotes: creditNotes,
                errorCode: 0,
            });
        }).catch(error => {
            console.error(error);
            this.setState({
                errorCode: 500,
                errorMessage: 'An unexpected error occured. Please try again later.',
            });
        });
    };

    updateSearchValue(searchTerm: string): void {
        this.setState({
            searchTerm: searchTerm,
        }, () => {
            this.loadCreditNotesFromApi();
        });
    }

    changeFilter(type: string) {
        this.setState({
            filter: type,
        }, () => {
            this.loadCreditNotesFromApi();
        });
    }

    getFilterButtonClass(type: string) {
        if (type === this.state.filter) {
            return 'btn btn-success buttons-csv buttons-html5'
        }

        return 'btn btn-secondary buttons-csv buttons-html5'
    }

    renderCreditNotes() {
        let sortedCreditNotes = this.state.creditNotes.sort((a, b) => {
            return a.publisherName.localeCompare(b.publisherName);
        });

        let creditNoteComponents = [];
        for (let key in sortedCreditNotes) {
            creditNoteComponents[key] = <CreditNoteComponent
                key={this.state.creditNotes[key].id}
                creditNote={this.state.creditNotes[key]}
                loadCreditNotesFromApi={this.loadCreditNotesFromApi}
            />;
        }

        return creditNoteComponents
    }

    getCollectionNotesTitle() {
        if (this.state.creditNotes.length) {
            const date = this.state.creditNotes[0].dateFrom.replace(' 00:00:00', '');

            return 'Credit notes for '
                + HelpFunctions.getFullMonthName(date, true)
                + ' ' + HelpFunctions.getFullYear(date, true)
        }

        return 'Credit notes'
    }

    componentDidMount(): void {
        this.loadCreditNotesFromApi();
    }

    render() {
        return <>
            <PageTitle
                title={this.getCollectionNotesTitle()}
                breadcrumbs={[
                    {
                        link: '/admin/dashboard',
                        name: 'Dashboard',
                    },
                    {
                        link: '/admin/credit-notes',
                        name: 'Credit notes',
                    },
                    {
                        name: 'Credit note collection',
                    },
                ]}
            />
            <PageRow>
                <div className="card">
                    <div className="card-body">
                        <div id="datatable-buttons_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                            <div className="row">
                                <div className="col-sm-2 col-md-2">
                                    <div id="datatable-buttons_filter" className="dataTables_filter">
                                        <label>Search for Publisher:</label>
                                        <Search
                                            updateSearchValue={this.updateSearchValue.bind(this)}
                                            minLength={0}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-9 filter_button">
                                    <div id="datatable-buttons_filter" className="dataTables_filter margin-right">
                                        <button
                                            className={this.getFilterButtonClass('all')}
                                            type="button"
                                            name="All credit notes"
                                            onClick= {() => {this.changeFilter('all')}}
                                        >
                                            <span className="float-left">Show all credit notes</span>
                                        </button>
                                    </div>
                                    <div id="datatable-buttons_filter" className="dataTables_filter margin-left margin-right">
                                        <button
                                            className={this.getFilterButtonClass('open')}
                                            type="button"
                                            name="Open credit notes"
                                            onClick= {() => {this.changeFilter('open')}}
                                        >
                                            <span className="float-left">Show open credit notes</span>
                                        </button>
                                    </div>
                                    <div id="datatable-buttons_filter" className="dataTables_filter margin-left">
                                        <button
                                            className={this.getFilterButtonClass('released')}
                                            type="button"
                                            name="All credit notes"
                                            onClick= {() => {this.changeFilter('released')}}
                                        >
                                            <span className="float-left">Show all released credit notes</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderCreditNotes()}
                <Error
                    isErrorCritical={this.state.isErrorCritical}
                    errorMessage={this.state.errorMessage}
                />
            </PageRow>
        </>
    }
}

export default CreditNoteCollection;
