import React from 'react';
import Ajax from '../../../../Helper/Ajax';
import DownloadCreditNotePdfButton from './DownloadCreditNotePdfButton';
import HelpFunctions from "../../../../Helper/HelpFunctions";
import {userGroup} from "../../../../Helper/UserGroups";

type DetailCreditNoteRouteMatch = {
  isExact: true,
  creditNoteId: string,
  page: string,
}

type DetailCreditNoteProps = {
  match: DetailCreditNoteRouteMatch,
}

type DetailCreditNoteState = {
  isErrorCritical: boolean,
  errorCode: number,
  errorMessage: string,
  creditNote: {
    id: string,
    creditNoteCollectionId: string,
    publisherId: string,
    subTotal: number,
    total: number,
    taxRate: number,
    createdDate: string,
    dateFrom: string,
    dateTo: string,
    notes: string,
    creditNoteAggregateStatus: number,
    creditNoteNumber: number,
    releaseDate: number,
  },
  creditNoteLineItems: [{
    position: number,
    description: number,
    amount: number,
  }],
  customerId: string,
  publisherAddress: {
    company: string,
    street: string,
    houseNumber: string,
    postCode: string,
    city: string,
    country: string,
  },
  vat: string,
  isExempted: boolean,
  vatNumber: string,
  pageCount: number,
}

class DetailCreditNote extends React.Component<DetailCreditNoteProps, DetailCreditNoteState> {
  creditNoteId: string = this.props.match.params.creditNoteId;

  page: string = this.props.match.params.page;

  state: DetailCreditNoteState = {
    isErrorCritical: true,
    errorCode: 0,
    errorMessage: '',
    creditNote: {},
    creditNoteLineItems: [],
    customerId: '',
    publisherAddress: {},
    vat: '0',
    vatNumber: '',
    pageCount: 0,
  };

  loadDetailCreditNoteInformationsFromApi() {
    const params = '?creditNoteId=' + this.creditNoteId + '&page=' + this.page;

    let url = '/admin/get-detail-credit-note-informations';
    if (localStorage['userGroup'] === userGroup.PUBLISHER) {
      url = '/get-detail-credit-note-informations';
    }

    const promise: Promise<string> = Ajax.createGetXhr(
        process.env.REACT_APP_NGINX_URL + url + params
    );

    promise.then(response => {
      const parsedResponse = JSON.parse(response);

      if (parsedResponse.errorMessage || parsedResponse.warningMessage) {
        this.setState({
          isErrorCritical: !parsedResponse.success,
          errorMessage: parsedResponse.errorMessage || parsedResponse.warningMessage,
        });
        return;
      }

      this.setState({
        creditNote: parsedResponse.data.creditNote,
        creditNoteLineItems: parsedResponse.data.creditNoteLineItems,
        customerId: parsedResponse.data.customerId,
        publisherAddress: parsedResponse.data.publisherAddress,
        vat: parsedResponse.data.vat,
        vatNumber: parsedResponse.data.vatNumber,
        pageCount: parsedResponse.data.pageCount,
        errorCode: 0,
        isExempted: parsedResponse.data.isExempted,
      });
    }).catch(error => {
      console.error(error);
      this.setState({
        errorCode: 500,
        errorMessage: 'An unexpected error occured. Please try again later.',
      });
    });
  }

  changeState(paritalState: Object) {
    this.setState({...paritalState})
  }

  componentDidMount(): void {
    this.loadDetailCreditNoteInformationsFromApi();
  }

  render() {
    return (
        <>
          <span className="fold-line" />
          <div className="row d-print-none">
            <div className="col-12">
              <div className="card-box">
                <div className="text-right">
                  <DownloadCreditNotePdfButton
                      creditNoteId={this.creditNoteId}
                      changeState={this.changeState.bind(this)}
                      openPdf={true}
                  />
                  <DownloadCreditNotePdfButton
                      creditNoteId={this.creditNoteId}
                      changeState={this.changeState.bind(this)}
                      openPdf={false}
                  />
                </div>
              </div>
            </div>
          </div>

          {this.renderCreditNote()}
        </>
    );
  }

  renderReleaseDate() {
    const date = new Date(this.state.creditNote.releaseDate);

    return HelpFunctions.getDate(date, true) + '. ' + HelpFunctions.getFullMonthName(date, true) + ' '
      + HelpFunctions.getFullYear(date, true);
  }

  renderPeriod() {
    const date = new Date(this.state.creditNote.dateFrom);

    return HelpFunctions.getFullMonthName(date, true) + ' ' + HelpFunctions.getFullYear(date, true);
  }

  renderHead() {
    if (this.page !== '1') {
      return '';
    }

    return <>
      <div className="row invoice">

        <div className="col-md-6 mail-window">
          <div className="mt-3">
            <p>Yadore GmbH • Bavariafilmplatz 7 • 82031 Grünwald</p>
            <p className="m-b-10">
              <span className="font-weight-bold">
                {this.state.publisherAddress.company}<br />
                {this.state.publisherAddress.street} {this.state.publisherAddress.houseNumber}<br />
                {this.state.publisherAddress.postCode} {this.state.publisherAddress.city}<br />
                {this.state.publisherAddress.country}<br />
              </span>
            </p>
          </div>
        </div>

        <div className="col-md-4 offset-md-2">
          <div className="mt-3 float-right">
            <p className="m-b-10">
              <span className="float-right">
                <span className="invoice-address">
                Yadore GmbH<br />
                Bavariafilmplatz 7<br />
                82031 Grünwald, Germany<br />
                </span>
                <span className="invoice-address">
                <a href="mailto:mail@yadore.com">
                  mail@yadore.com
                </a><br />
                <a href="https://www.yadore.com/"
                   target="_blank"
                   rel="noopener noreferrer">
                  www.yadore.com
                </a><br />
                </span>
              <span className="invoice-address">
                Managing Director: Fabian Gärtner<br />
                Registration number: HRB 235343<br />
                Tax ID: DE314199135<br />
              </span>
                <span className="invoice-address">
                <b>Customer ID: {this.state.customerId}</b><br />
                VAT: {this.state.vatNumber}<br />
                Date: {this.renderReleaseDate()}<br />
              </span>
              </span>
            </p>
          </div>
        </div>

      </div>

      <div className="row mt-3">
        <div className="col-sm-6">
          <p>
            <b>Credit note number: {this.state.creditNote.creditNoteNumber}</b>{this.renderBadge()}<br /><br />
            Dear Sir and Madame,<br />
            below you find the revenue for {this.renderPeriod()}
          </p>
        </div>
      </div>
    </>;
  }

  renderBadge() {
    if (this.state.creditNote.creditNoteAggregateStatus === 4) {
      return <> <span className="vertical-align-center">
        <div className="mdi-large badge badge-red">aborted</div>
      </span></>;
    }
    if (this.state.creditNote.creditNoteAggregateStatus === 6) {
      return <> <span className="vertical-align-center">
        <div className="mdi-large badge badge-red">canceled</div>
      </span></>;
    }
    if (this.state.creditNote.creditNoteAggregateStatus === 7) {
      return <> <span className="vertical-align-center">
        <div className="mdi-large badge badge-red">account deleted</div>
      </span></>;
    }

    return '';
  }

  renderCreditNoteLineItemTable() {
    if (this.state.creditNoteLineItems.length < 1) {
      return '';
    }

    return (
        <div className="row">

          <div className="col-12">
            <div className="table-responsive">
              <table className="table mt-4 table-centered credit-note-table">
                <thead>
                <tr className="invoice-font-size invoice">
                  <th className="no-border-right" style={{width: '5%'}}>Position</th>
                  <th className="no-border-right">Description</th>
                  <th className="text-right no-border-right" style={{width: '20%'}}>Amount</th>
                </tr>
                </thead>
                <tbody>
                {this.renderCreditNoteLineItems()}
                </tbody>
              </table>
            </div>
          </div>

        </div>
    );
  }

  renderCreditNoteLineItems() {
    return this.state.creditNoteLineItems.map((creditNoteLineItem, i) => {
      const page = parseInt(this.page);
      let tehVar = 0;

      tehVar += (page - 2) * 20;

      if (page > 1) {
        tehVar += 10;
      }

      if (page === 1) {
        tehVar = 0;
      }

      return (
          <tr key={i} className="invoice-row">
            <td className="invoice-cell">{i + 1 + tehVar}</td>
            <td className="invoice-cell">{creditNoteLineItem.description}</td>
            <td className="text-right invoice-cell">{HelpFunctions.addCommas(creditNoteLineItem.amount)} €</td>
          </tr>
      );
    });
  }

  renderTaxRateMessage() {
    if (this.state.creditNote.taxRate !== 0 && !this.state.isExempted) {
      return '';
    }

    if (this.state.isExempted) {
      return <p className="text-muted">The credit recipient is a small business owner according to §19 UStG</p>
    }

    return <p className="text-muted">
      As you/your company is not based in Germany the reverse charge method is used.
      The amount will be paid to your bank account around 45 days after months end.
    </p>;
  }

  renderTotal() {
    if (this.page !== this.state.pageCount.toString()) {
      return '';
    }

    let taxRate = this.state.creditNote.taxRate;
    if (this.state.isExempted) {
      taxRate = 0;
    }

    return <>
      <div className="row">
        <div className="col-sm-7">
          <div className="clearfix">
            {this.renderTaxRateMessage()}
          </div>
        </div>
        <div className="col-sm-1"/>
        <div className="col-sm-4 invoice-font-size">
          <div className="float-right">
            <p>
              <b>Revenue total:</b>
              <span className="float-right">
                        &nbsp;&nbsp;&nbsp;&nbsp;{HelpFunctions.addCommas(this.state.creditNote.subTotal)} €
                      </span>
            </p>
            <p>
              <b>VAT ({taxRate}%):</b> <span className="float-right">{HelpFunctions.addCommas(this.state.vat)} €</span>
            </p>
            <h4 className="text-right">
              {HelpFunctions.addCommas(this.state.creditNote.total)} €
            </h4>
          </div>
          <div className="clearfix" />
        </div>

      </div>
    </>;
  }

  renderNextPageMessage() {
    if (this.page === this.state.pageCount.toString()) {
      return '';
    }

    return <span>The credit note details will continue on the next page.</span>
  }

  renderCreditNote() {
    return (
        <div className="row no-border">
          <div className="col-12 no-border">
            <div className="card-box a4-borders">

              <div className="clearfix text-align-center detail-credit-note-logo">
                <img src="/images/yadore.svg" alt="yadore.png" height="64px" />
              </div>

              <br /><br /><br />

              {this.renderHead()}

              {this.renderCreditNoteLineItemTable()}

              {this.renderTotal()}

              {this.renderNextPageMessage()}
            </div>
          </div>
        </div>
    );
  }
}

export default DetailCreditNote;
